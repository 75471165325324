
.activities-container{ 
    display: flex;
    flex-direction: column; 
    max-height: 75vh;
    overflow-x: hidden;
}

.activities-list { 
    overflow-x: hidden;
    overflow-y: auto; 
}

.activities-list-2{ 
    overflow-x: hidden;
    position: relative;
    overflow-y: auto; 
}

.planedList{
    margin: 1rem;
    /* min-height: fit-content; */
    max-height: 35vh !important;
    width: 97%;
    list-style-type:none;
    /* overflow-y: auto;  */
}





.CallLogList{
    margin: 1rem;
    /* height: 71vh !important; */
    width: 97%;
    list-style-type:none;
    /* overflow-y: auto;  */
    
}
 
.completedList{
    margin: 1rem;
    /* min-height: fit-content; */
    /* max-height: 35vh !important; */
    width: 97%;
    list-style-type:none;
    overflow-y: auto; 
}
.completedList li:hover, .planedList li:hover, .CallLogList li:hover{
    background-color: #5cada9;
    color: #fff;
    cursor: pointer;
}


.Activites .emptyList{
    width: 100%;
    height: 100%;
    background: url('../../img/Empty.svg') ;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transition: transform 0.3s ease;

}
 
.Activites .emptyList{
    height: 30vh;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}


.status{
    background-color: #5cada9;
    color: #fff;
    /* padding: 1px 3px; */
    
}

