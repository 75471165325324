#custom-table-itemmaster{
  font-family: "Inter", sans-serif;
  margin-left: 5%;
  width: 95%;
  max-height: 100%;
}
#pos-report-data{ 
  width: 95%;
  margin-left: 5%;
  overflow-x: hidden;
}

#custom-table-pos{
  font-family: "Inter", sans-serif;
  margin-left: 2%;
  width: 98%;
  max-height: 100%;
}

#custom-table-pos{
  font-family: "Inter", sans-serif;
  margin-left: 3%;
  width: 98%;
  height: 100%;
}
#custom-table-supplier{
  font-family: "Inter", sans-serif;
  margin-left: 3%;
  width: 98%;
  height: 100%;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.custom-table-responsive {
  height: 38rem; /* Adjust the height as needed */
  /* width: inherit; */
  overflow: auto; /* Horizontal scrolling */
}

.custom-table-responsive table{
  table-layout: fixed;
}

.custom-table-responsive .thead-fixed {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #FAFAFA;
  height: 5rem;
}

.custom-table-responsive tbody {
  max-height: calc(100% - 40px); /* Subtract header height from container height */
  overflow-y: auto; /* Vertical scrolling for tbody */
  border-color: inherit;
  background-color: #FFFFFf;
}
  
.custom-table-responsive th {
  white-space: nowrap; /* Prevent wrapping */
}

/* Hide scrollbar track */
::-webkit-scrollbar {
  width: .5rem; /* Width of the scrollbar */
  height: .5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cac9c9; /* Color of the scrollbar handle */
  border-radius: 4px; /* Radius of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cac9c9; /* Color of the scrollbar handle on hover */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

/* Corner */
::-webkit-scrollbar-corner {
  background: transparent; /* Color of the scrollbar corner */
}

.react-select__dropdown-indicator {
  display: none;
}
