/* .header{
    text-align: center;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  
  } */
  .header img{
      height: 20%;
      width: 90%;
      /* margin-top: 15px; */
      border: 1px solid #ffff;
      justify-content: flex-start;
  }
  .header .title{
    flex: 3;
    padding-right: 10%;
  }
  .top_div{ 
    max-width: 650px; 
    transform: translate(-50%, -50%);
  }
  .title{ 
    position: relative;
    color: rgb(85, 85, 85);
    font-weight: 600;
  }
  .title::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffff;
     animation: showRing 2s ease   forwards;
     animation-delay: 0.1s
  }
  

 
  .container-md{
    max-width: 800px; 
 }

 .container-sm {

    /* max-width: 576px;  */
    background: #ffff;
    border-radius: 2%;
  }

  .enquiry_from{
    /* height: 100vh; */
  
    /* width: 80vw; */
    margin-top: 10%;
    border: black 2px solid;
    border-radius: 3%;
    text-align: center;
    justify-content: center;
  
  }
  .title-sub{
    color: rgb(255 153 0);
  }
   
  
.input-trenprant{
  
    border: none;
    border-bottom: 1px solid #000;  
    outline: none;
    background-color: transparent;
  }
  small{
    color: red;
  }
  .enquiry_submit_button{
    background: linear-gradient(#29bffe,rgb(1, 81, 255));
    color: #ffff;
    font-weight: 500;
    width: 40%;
      margin-top: 10px;
      margin-bottom: 10px;
      border-end-end-radius: 20px;
      border-end-start-radius: 20px;
      border-start-end-radius: 20px;
      border-start-start-radius: 20px;
       outline: none;
       
  }

  .enquiry_submit_button:hover{
    /* color: #f90; */
   
    color: #ffff;
    background: linear-gradient(rgb(1, 81, 255), #29bffe);
    /* background:linear-gradient(); */
  }
  .submit_button_wrao{
    text-align: center;
  }

  
@keyframes showRing {
    100%{
      width: 0%;
    }
    
  }


  @media (min-width: 768px) {
    .comman_circle {
      width: 100px; /* Adjust the width for smaller screens */
      height: 100px; /* Adjust the height for smaller screens */
    }
    .enquiryForm{
      width: 650px;
     }
    
  .header .title{
     
    padding-right: 20%;
  }
  }


  @media (max-width: 768px) {
  
    .header img{
       margin-left: 10px;
       margin-top: 0px;
    
  }
}

@media (max-width: 576px) {
    
    .header{
      display: block;
      
      margin: 0 auto; 
    }
    .enquiryForm{
      width: 350px;
     }
    .header img{
      margin-top: 0px;
      height: 30%;
      width: 30%; 
    }
    
    .header .title{
      flex: 3;
      padding-right: 0%;
    }
  }
  @media (max-width: 375px) {
 
     .header{
       margin:auto;
       margin-left: 0%;
       
     }
     .enquiryForm{
      width: 300px;
     }
      .header img{
        margin-top: 0px;
        margin-left: 0%;
        height: 30%;
        width: 30%; 
  }     
  
      .enquiry_submit_button{
        
        margin-left: 25%;
      }
     
     
  }