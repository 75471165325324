 .totalMargin{
   display: flex;
   flex-wrap: nowrap;
 }
 .totalMargin>*{
   justify-content: flex-start;
 
 }
 /*  margin-left: 40%; */

 .detailed-report-container table tr td {
  width: 100px; /* Adjust the width as needed */
}
.detailed-report-container table tr input {
  width: 120px; /* Adjust the width as needed */
}


.css-1dimb5e-singleValue{
  user-select:auto;
  cursor: pointer;
}

#react-select-2-input{
  user-select: text;
  cursor: pointer;
}