.transparent-modal .modal-content {
    background: transparent;
    border: none;
    box-shadow: none;
}

.transparent-modal .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5); /* Adjust this value for desired transparency */
}

.transparent-modal .modal-dialog {
    max-width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}