body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  font-family: 'Inter';
  font-size: .9rem;
  letter-spacing: 0.02rem !important;
}
p, a, li, h1, h2, h3, h4, h5, h6, td, tr, Select {
  color: #000000;
}

.modal-title {
  color: #000000 !important; /* Your desired color */
}

.ellipsis-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 16px; /* Adjust height of the button */
  width: 8px;   /* Adjust width of the button */
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

/* The vertical ellipsis itself */
.ellipsis-vertical {
  display: inline-block;
  width: 2px;  /* Width of each dot */
  height: 4px; /* Height of each dot */
  background-color: black;
  margin: 2px 0; /* Space between dots */
}

/* Each dot in the vertical ellipsis */
.ellipsis-vertical::before {
  content: "";
  display: block;
  width: 2px;
  height: 4px;
  background-color: black;
  margin: 2px 0; /* Space between dots */
}

@media (max-width: 1366px ) and (max-height: 768px ) {
  *{
    font-size: 0.8rem;
  }
}
 
code {
  font-family: "Inter", sans-serif;
}

:root {
  --border_color:  #000000;
  font-family: "Inter", sans-serif;
}


div.ag-theme-alpine div.ag-row {
  font-family: 'Inter';
  font-size: .8rem;
}

/* Custom styles for the clear indicator */
/* .react-select__clear-indicator svg {
  width: 8px; 
  height: 8px; 
  padding: 0px;
} */


.css-tj5bde-Svg{
  height: 16px;
  width: 16px;
}

.css-1xc3v61-indicatorContainer{
  padding: 2px !important;
}

.modal-transparent {
  background-color: transparent !important;
}

.modal-transparent .modal-content {
  background-color: transparent;
  border: none;
}

.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: .5;
  }
}
