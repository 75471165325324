/* .node-circle {
    fill: #87CEFA;
    stroke: #1E90FF;
    stroke-width: 2;
  } */
  
  .node-text {
    font-family: 'Arial, sans-serif' !important;
    font-size: 16px !important;
    fill: black !important;
  }
  
  .node-attribute {
    font-family: 'Arial, sans-serif' !important;
    font-size: 12px !important;
    fill: rgb(0, 0, 0) !important;
  }
  .rd3t-node{
    stroke:none !important; 
  }
  
  #current-node{
    fill: aquamarine !important;
  }

  #link{
    stroke: blue;
    stroke-width:0.5;
  }

  .rd3t-leaf-node{
    stroke: black !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  