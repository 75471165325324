

  .tree {
    width: 100%;
    height: auto;
    text-align: center;
    overflow-x: auto; /* Enables horizontal scrolling for large trees */
    padding: 20px;
  }
  
  .tree ul {
    position: relative;
    padding-top: 20px;
    transition: all 0.5s;
  }
  
  .tree li {
    display: inline-table;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 10px;
    transition: all 0.5s;
  }
  
  .tree li::before,
  .tree li::after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    width: 51%;
    height: 10px;
    border-top: 1px solid #ccc;
  }
  
  .tree li::after {
    left: 50%;
    right: auto;
    border-left: 1px solid #ccc;
  }
  
  .tree li:only-child::after,
  .tree li:only-child::before {
    content: '';
    display: none;
  }
  
  .tree li:only-child {
    padding-top: 0;
  }
  
  .tree li:first-child::before,
  .tree li:last-child::after {
    border: 0 none;
  }
  
  .tree li:last-child::before {
    border-right: 1px solid #ccc;
    border-radius: 0 5px 0 0;
  }
  
  .tree li:first-child::after {
    border-radius: 5px 0 0 0;
  }
  
  .tree ul ul::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
  }
  
  .tree li div {
    display: inline-grid;
  
    text-decoration: none;
    transition: 0.5s;
  }
  
  
  .tree li div:hover,
  .tree li div:hover + ul li div {
    color: #000;
    background: #c8e4f8;
    border: 1px solid #94a0b4;
  }
  
  .tree div {
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #666;
    width: 6.5rem;
    margin: 0 .5rem;
    padding: .2rem !important;
    
    text-transform: uppercase;
    letter-spacing: normal;
    font-weight: 500;
  }
  .tree div span{
    font-size: .8rem !important;
  }
  .tree li   div:hover + ul li::after,
  .tree li   div:hover + ul li::before,
  .tree li   div:hover + ul::before,
  .tree li   div:hover + ul ul::before {
    content: '';
    border-color: #94a0b4;
  }
  .currentModel{
    background:   #78dee4;
    
  }

  /* Responsive Styles */
  @media (max-width: 1400px) {
    .tree ul {
      scale: 1 !important;
    }
  
  }
  @media (max-width: 1200px) {
    .tree ul {
      scale: .8 !important;
    }
  
  }
  @media (max-width: 992px) {
    .tree ul {
      scale: .7 !important;
    }
  
  }
  @media (max-width: 768px) {
    .tree ul {
      scale: .6 !important;
    }
  
  }

  /*  */
  @media (max-width: 480px) {
    .tree ul {
      scale: .5 !important;
    }
  }
  