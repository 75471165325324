.dynamic-image {
    position: absolute;
    top: 50%;
    left: -100px;  /* Initial position outside the screen */
    transform: translateY(-50%); /* Center vertically */
    animation: moveImage 5s infinite linear;
  }
  
  @keyframes moveImage {
    0% {
      left: -100px; /* start from left */
    }
    50% {
      left: 50%;  /* Move to center */
      transform: translateX(-10%) translateY(-10%); /* Center the image horizontally */
    }
    100% {
      left: 65%; /* move to the far right */
      transform: translateX(-0%) translateY(-100%)
    }
  }
  