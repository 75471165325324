 /* button */
.UnauthorizedBackbutton {
    margin: 30px;
    padding: 15px 40px;
    border: none;
    outline: none;
    color: #FFF;
    cursor: pointer;
    font-size: 1rem;
    position: relative;
    z-index: 0;
    border-radius: 12px;
    border: .3rem #FFF solid;
  }
  .UnauthorizedBackbutton::after {
    content: "";
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #07080b;
    left: 0;
    top: 0;
    border-radius: 10px; 
  }
  /* glow */
  .UnauthorizedBackbutton::before {
    content: "";
    background: linear-gradient(
      45deg,
      #FF0000, #FF7300, #FFFB00, #48FF00,
      #00FFD5, #002BFF, #FF00C8, #FF0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 600%;
    z-index: -1;
    width: calc(100% + 4px);
    height:  calc(100% + 4px);
    filter: blur(8px);
    animation: glowing 20s linear infinite;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    opacity: 0;
  }
  
  @keyframes glowing {
    0% {background-position: 0 0;}
    50% {background-position: 400% 0;}
    100% {background-position: 0 0;}
  }
  
  /* hover */
  .UnauthorizedBackbutton:hover::before {
    opacity: 1;
  }
  
  .UnauthorizedBackbutton:active:after {
    background: transparent;
  }
  
  .UnauthorizedBackbutton:active {
    color: #000;
    font-weight: bold;
  }