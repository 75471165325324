.itemMasterContantWarp{
    width: 100%;
    height:100vh;
    overflow-y: auto;
}

.itemMasterContantWarp head{
    width: 98%;
    height: 5rem;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.itemMasterInputWarp{
    width: 98%;
    display: flex;
    flex-direction: row;
    margin: 1rem auto;
    justify-content:space-around;
}

.ItemMasterDocWrap{
    display: flex;
}
.itemMasterImageWarp{
    width: 25%;
    padding: 1rem !important;
    margin-right: 1rem;
}

.itemMasterDocWarp_head{
    width: 80%;
    padding: 1rem !important;
}
.itemMasterDocWarp{
    display: flex;
    flex-direction: row;
}

.zoom_img_wrap{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.zoom_img_wrap img{
    scale: 2;
}
.itemmaster-save{
    width: 10rem;
}


@media screen and (max-width: 1440px) {
    .itemMasterImageWarp{
        width: 28%;
        padding: 1rem !important;
        margin-right: 1rem;
    }
    .itemMasterDocWarp{
        /* width: 72%; */
        padding: 1rem !important;
    
    }
  }

  @media screen and (max-width: 1024px) {
    .ItemMasterDocWrap{
        display: block;
    }
    .itemMasterImageWarp{
        width: 50%;
        padding: 1rem !important;
        margin-right: 1rem;
        margin-bottom: .5rem;
    }
    .itemMasterDocWarp{
        width: 100%;
        padding: 1rem !important;
    
    }
  }

.alteruom_fixed{
    display: flex;
}
.alteruom_fixed label{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    margin: 0;
}

.alteruom_fixed .alteruom_fixed_check{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    margin: 1rem;
    
}
/* .itemMasterInputWarp div{
    display: flex; 
    justify-content: space-between;
} */
