/* custom table css */
.StaanTable {
  border-collapse: collapse;
  width: 100%;
}

.StaanTable td,
.StaanTable th {
  text-align: left;
  padding: .5rem 0rem;
  border-bottom: 1px solid #ccc;

}

.StaanTable th {
  background-color: #f9f9f9;
  font-weight: bold;
  color: #333;
}


/* .StaanTable tr:nth-child(even) {
  background-color: #dddddd;
} */

.StaanTable td button {
  padding: 5px 8px;
  margin-right: 5px;
  border: none;
  border-radius: 60px;
  font-size: 14px;
  cursor: pointer;
}

.StaanTable td .edit-button {
  background-color: #ffff;
  color: #198754;
  border: 2px solid #198754;
  font-size: .7rem;
}

.StaanTable td .edit-button i {
  color: #198754;
}

.StaanTable td .edit-button svg {
  fill: #198754;
}

.StaanTable td .edit-button:hover {
  background-color: #198754;
  color: #fff;
}

.StaanTable td .edit-button:hover i {
  color: #fff;
}

.StaanTable td .save-button {
  background-color: #fff;
  color: #007bff;
  border: 2px solid #007bff;
  font-size: .7rem;
}

.StaanTable td .save-button:hover {
  background-color: #fff;
  color: #007bff;
}

.StaanTable td .add-button {
  background-color: #198754;
  color: #fff;
  font-size: .7rem;
}

.StaanTable td .delete-button {
  background-color: #ffff;
  color: #dc3545;
  border: 2px solid #dc3545;
  font-size: .7rem;
}
.StaanTable td .delete-button i {
  color: #dc3545;
}

.StaanTable td .delete-button:hover {
  background-color: #dc3545;
  color: #ffff;
}
.StaanTable td .delete-button:hover i {
  color: #fff;
}
.StaanTable td input[type="text"],
.StaanTable td input[type="email"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.stock-addition-table {
  height: 80%;
  scroll-behavior: auto;
  overflow-y: auto;
}

.StaanTable tbody tr {
  font-size: 0.8rem;
}