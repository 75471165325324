.tp_inputBox{
    width: 120px !important;
    padding: 6px 2px !important;
}

input.tp_inputBox{
    font-size: .8rem !important;
}

.tp_option{
    font-size: .8rem !important;
    padding: 4px 8px !important;
}

.tp_dropdownWrapper.tp_dropdownActive{
    padding: 10px !important;
}

button.tp_confirmBtn{
    padding: 1px 6px !important;
}