.table-header {
    background-color: #f9f9f9;
    color: #333;
    text-align: center;
    cursor: pointer;
    user-select: none; /* Prevent text selection when clicking */
}

.table-header th {
    padding: 10px;
    position: relative;
}

.table-header th .sort-indicator {
    margin-left: 5px;
    font-size: 12px;
    color: #888;
    position: absolute;
    right: 10px;
}

.draggable-row {
    transition: background-color 0.2s ease;
}

.draggable-row.dragging {
    background-color: #e0f7fa; /* Light blue highlight when dragging */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.draggable-row:hover {
    background-color: #f1f1f1; /* Highlight on hover */
}
